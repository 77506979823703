import '../scss/plugins/_swiper.scss'

import Swiper from 'swiper'
import { Navigation, Pagination, EffectFade, Autoplay, A11y } from 'swiper/modules'

const settings = {
	pagination: {
		el: '[data-swiper-pagination]',
		type: 'fraction',
	},

	navigation: {
		nextEl: '[data-swiper-button-next]',
		prevEl: '[data-swiper-button-prev]',
	},
}

const modules = [Navigation, Pagination, A11y]

const sliderGalleries = document.querySelectorAll('[data-swiper-gallery]')
if (sliderGalleries.length) {
	sliderGalleries.forEach(gallery => {
		const slidesPerView = Number(gallery.getAttribute('data-slides-per-view'))
		let slidesPerViewTablet = 1
		if (slidesPerView >= 3) {
			slidesPerViewTablet = Math.round(slidesPerView / 2)
		}
		const slidesPerViewMobile = slidesPerView > 4 ? 2 : 1
		const sliderGallery = new Swiper(gallery, {
			...settings,
			modules: [...modules, EffectFade],
			slidesPerView: slidesPerViewMobile,
			spaceBetween: 14,
			autoHeight: true,
			loop: true,
			breakpoints: {
				667: {
					slidesPerView: slidesPerViewTablet,
				},
				992: {
					slidesPerView: slidesPerView,
				},
			},
		})
	})
}

let testimonialsSwiper

function initTestimonialsSwiper() {
	if (!document.querySelector('[data-swiper-testimonials]')) return

	if (testimonialsSwiper) {
		testimonialsSwiper.destroy(true, true)
	}

	testimonialsSwiper = new Swiper('[data-swiper-testimonials]', {
		...settings,
		modules: [...modules, EffectFade, Autoplay],
		speed: 400,
		loop: true,
		effect: 'fade',
		fadeEffect: {
			crossFade: true,
		},
		grabCursor: true,
		spaceBetween: 24,
		slidesPerView: 1,
		autoHeight: window.innerWidth < 1280,
		breakpoints: {
			1280: {
				autoHeight: false,
			},
		},
	})
}

initTestimonialsSwiper()
window.addEventListener('resize', initTestimonialsSwiper)

const hero = new Swiper('[data-swiper-hero]', {
	pagination: {
		el: '[data-swiper-pagination]',
		clickable: true,
		type: 'bullets',
	},
	modules: [...modules, EffectFade, Autoplay],
	autoplay: {
		delay: 4000,
		disableOnInteraction: true,
	},
	speed: 400,
	loop: true,
	effect: 'fade',
	fadeEffect: {
		crossFade: true,
	},
	grabCursor: true,
	spaceBetween: 24,
	slidesPerView: 1,

	on: {
		init() {
			stopAutoplayOnButtonClick(this)
		},
	},
})

const posts = new Swiper('[data-swiper-posts]', {
	...settings,
	modules: [...modules],
	slidesPerView: 1.1,

	breakpoints: {
		640: {
			spaceBetween: 6,
			slidesPerView: 2.1,
		},
		1280: {
			spaceBetween: 12,
			slidesPerView: 3,
		},
	},
})

function slidesPerViewMembers(datasetValue) {
	switch (datasetValue) {
		case '1':
			return { 640: 2, 900: 3, 1024: 1, 1280: 1 }
		case '2':
			return { 640: 2, 900: 3, 1024: 2, 1280: 2 }
		default:
			return { 640: 2, 900: 3, 1024: 3, 1280: 4 }
	}
}
document.querySelectorAll('[data-swiper-members]').forEach(memberSlider => {
	const slidesPerView = slidesPerViewMembers(memberSlider.dataset.swiperMembers)

	new Swiper(memberSlider, {
		...settings,
		modules: [...modules],
		loop: true,
		slidesPerView: 1,
		spaceBetween: 16,
		breakpoints: {
			640: {
				slidesPerView: slidesPerView[640],
			},
			900: {
				slidesPerView: slidesPerView[900],
			},
			1024: {
				slidesPerView: slidesPerView[1024],
			},
			1280: {
				slidesPerView: slidesPerView[1280],
			},
		},
	})
})

const cards = new Swiper('[data-swiper-cards]', {
	...settings,
	modules: [...modules],
	centerInsufficientSlides: true,
	slidesPerView: 1.2,
	spaceBetween: 16,
	breakpoints: {
		640: {
			slidesPerView: 2.25,
		},
		1024: {
			slidesPerView: 3.2,
		},
		1280: {
			slidesPerView: 4.25,
		},
		1920: {
			slidesPerView: 5,
		},
		2560: {
			slidesPerView: 7,
		},
		3840: {
			slidesPerView: 10,
		},
	},
})

function stopAutoplayOnButtonClick(swiperInstance) {
	const buttons = swiperInstance.el.querySelectorAll('.button[href^="#"]')
	buttons.forEach(button => {
		button.addEventListener('click', function () {
			if (swiperInstance.autoplay) {
				swiperInstance.autoplay.stop()
			}
		})
	})
}
